/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    img: "img",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "images/Wayland.png",
    alt: ""
  }), "Con la actualización a Kubuntu 17.10 me he quedado un poco despagado de que éste no traiga Wayland por defecto para plasma, así que me he decidido a instalarlo para de una vez por todas probarlo en mi PC habitual. La instalación es muy sencilla, no tiene mas que:"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "sudo apt install plasma-workspace-wayland")), "\n", React.createElement(_components.p, null, "A continuación he reiniciado y he abierto una sesión de Plasma con Wayland, mi primera sorpresa ha sido encontrarme el teclado estadounidense en lugar del que tenía configurado. La cosa ha seguido con aplicaciones que dan fallo al cerrarse, el touchpad desconfigurado y algún atajo de teclado que no me esperaba. Todo detalles mínimos o configuraciones, pero es cierto que aún no está todo lo pulido para ir por defecto en la distribución. ¿Lo tendremos para la próxima versión? Yo por ahora ya estoy funcionando con él."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
