/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    img: "img",
    a: "a",
    code: "code",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "images/7196672562_25ca63595e_z-300x200.jpg",
    alt: ""
  }), "Me he llevado una grata sorpresa desde la última vez que intenté configurar mi PC para utilizar jackd, ya sabéis, en GNU el audio a nivel de usuario común funciona mediante Pulseaudio, pero a nivel profesional, para edición y producción de música se utiliza Jack, un sistema mediante el que las aplicaciones profesionales se conectan para permitir la mínima latencia entre la entrada y la salida, esto permite que por ejemplo si tenemos un teclado MIDI conectado y tocamos una tecla no haya un retraso no esperado en escuchar la nota. Lo normal en Linux es que tuvieras tu sistema configurado con jackd o con Pulseadio, eran en gran parte excluyentes y cambiar entre uno y otro daba bastantes dolores de cabeza. Pero recientemente a aparecido un puente que permite a Pulseaudio trabajar como un dispositivo más gestionado por Jack, es decir que podremos encaminar el audio de nuestras aplicaciones no profesionales, como Firefox mismo, hacia todo el sistema de Jack. Vamos a ver cómo:"), "\n", React.createElement(_components.p, null, "En primer lugar vamos a usar KXStudio para tener las últimas versiones de las aplicaciones y utilidades para audio a nivel profesional. Descargamos e instalamos el .deb desde la web de ", React.createElement(_components.a, {
    href: "http://kxstudio.linuxaudio.org/Repositories"
  }, "KXStudio"), "."), "\n", React.createElement(_components.p, null, "Actualizamos con los nuevos repositorios e instalamos tanto el gestor de Jack, Cadence como el puente entre Pulseaudio y Jack. ", React.createElement(_components.code, null, "sudo apt update sudo apt upgrade sudo apt install pulseaudio-module-jack cadence")), "\n", React.createElement(_components.p, null, "Vamos a trabajar con audio, así que nos añadimos al grupo pertinente: ", React.createElement(_components.code, null, "sudo adduser usuario audio")), "\n", React.createElement(_components.p, null, "Y abrimos cadence y lo configuramos tal como se muestra, marcando que se ejecute jackd y el bridge al inicio, además habrá que configurar jackd para que no se produzcan xruns, lo que queda fuera del alcance de este tutorial."), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "images/cadence.png",
    alt: ""
  })), "\n", React.createElement(_components.p, null, "Con esto ya podemos hacer conectar Pulseaudio mediante Jack, lo vemos a continuación en Catia, lo encontraréis en la pestaña tools de Cadence."), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "images/catia.png",
    alt: ""
  })), "\n", React.createElement(_components.p, null, " "), "\n", React.createElement(_components.h3, null, "Resolución de problemas"), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "images/jackretask-350x424.png",
    alt: ""
  }), "Después de seguir estos pasos me he encontrado con un problema, cuando conecto los auriculares a la toma no se escucha absolutamente nada, lo he solucionado mediante la utilidad HDAJackRetask."), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "sudo apt install alsa-tools-gui")), "\n", React.createElement(_components.p, null, "Abrimos la utilidad y trasteamos un poco con ella hasta encontrar nuestra configuración adecuada."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
