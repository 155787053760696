/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    blockquote: "blockquote",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Para instalar el sistema gráfico, es decir el Xorg, instalé estos paquetes:"), "\n", React.createElement(_components.p, null, "xserver-xorg : El sistema grafico en sí. xinit : El inicializador del sistema grafico xterm : Una terminal de las X"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "$ aptitude install xserver-xorg xinit xterm")), "\n"), "\n", React.createElement(_components.p, null, "Para probarlas simplemente usamos el comando xinit y nos saldrá la xterm con el ratón y todo."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
