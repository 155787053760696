/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ol: "ol",
    li: "li",
    a: "a",
    code: "code",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "El siguiente paso que vamos a dar con nuestra RPi va a ser conectarla a nuestra red para acceder a ella mediante SSH, además comprobaremos que tiene acceso a Internet."), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Cogemos un cable de red Ethernet y lo conectamos a Raspberry y a nuestro router."), "\n", React.createElement(_components.li, null, "Miramos en la configuración del router que IP tenemos asignada a la RPi y de paso si el router nos da la opción hacemos que esa IP sea fija, si no podéis siempre podéis usar ", React.createElement(_components.a, {
    href: "http://electrolito.entramado.net/2012/11/17/establecer-ip-fija-raspberry-pi/"
  }, "este tutorial"), "."), "\n", React.createElement(_components.li, null, "Nos conectamos por SSH con el siguiente comando en la terminal de Linux:", React.createElement(_components.code, null, "ssh -l pi -x NUESTRA_IP"), " Le estamos diciendo a SSH que nos conecte con el usuario ", React.createElement(_components.em, null, "pi"), " y con permisos para ejecutar el entorno gráfico de GNU/Linux. Donde pone NUESTRA_IP debemos poner la IP que hemos mirado antes."), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "images/ssh_rpi.png"
  }, "Conectado a la RPi por SSH.")), "\n", React.createElement(_components.ol, {
    start: "4"
  }, "\n", React.createElement(_components.li, null, "Ponemos la contraseña por defecto para entrar, si no lo hemos cambiado. Recomiendo cambiarla para que nadie pueda entrar en nuestra Raspberry Pi en algún descuido, para ello podemos ir a la configuración con este comando:", React.createElement(_components.code, null, "sudo raspi-config")), "\n", React.createElement(_components.li, null, "Ya podemos probar si tenemos internet a través del Ethernet, para esto hacemos un simple ping a cualquier dirección fiable, en este caso, mi blog: ", React.createElement(_components.code, null, "ping litox.entramado.net")), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "images/ping_rpi1.png"
  }, "Ping a mi blog.")), "\n", React.createElement(_components.p, null, "Cuando vemos que como en la imagen tenemos unos cuantos pings no se han perdido paramos el proceso con ctrl+c."), "\n", React.createElement(_components.ol, {
    start: "6"
  }, "\n", React.createElement(_components.li, null, "Y ya tenemos nuestra Raspberry configurada para empezar a instalar cosas."), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
