/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    code: "code",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.p, null, "Para obtener el efecto de fondo traslúcido borroso en las consolas de Plasma añadimos al final del archivo ~/.bashrc el siguiente código: ", React.createElement(_components.code, null, "# blur if [[ $(ps --no-header -p $PPID -o comm) =~ yakuake|konsole ]]; then for wid in $(xdotool search --pid $PPID); do xprop -f _KDE_NET_WM_BLUR_BEHIND_REGION 32c -set _KDE_NET_WM_BLUR_BEHIND_REGION 0 -id $wid; done fi"), " Visto en ", React.createElement(_components.a, {
    href: "https://www.reddit.com/r/kde/comments/7szqqk/i_implemented_a_new_blur_effect_in_kde_it/"
  }, "el hilo de reddit de KDE"), ".");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
