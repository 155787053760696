import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Callout from '../components/callout';
import PostImage from '../components/postimage';

const StyleGuidePage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle} menuLinks={data.site.siteMetadata.menuLinks}>
      <h1>Guía de estilo</h1>
      <p><a href='https://tools.keithjgrant.com/theme-machine/wheel/?mode=f&l=93.1&c=16.2&h=132%7C162%7C102%7C16'>Selector de color</a></p>
      <p><a href='https://tools.keithjgrant.com/theme-machine/palette/?l=0.9309999999999999&c=0.162&h=132!&h=162&h=102&h=16'>Paleta de colores</a></p>
      <h2>Tokens</h2>
      <h3>Paleta de color</h3>
      <div style={{display: 'inline'}}>
        <div style={{backgroundColor: 'var(--stone-1)', display: 'inline-block', width: '100px', height: '160px'}}></div>
      <div style={{backgroundColor: 'var(--jungle-accent)', display: 'inline-block', width: '100px', height: '160px'}}></div>
        <div style={{backgroundColor: 'var(--yellow-accent)', display:'inline-block', width: '100px', height: '160px'}}></div>
        <div style={{backgroundColor: 'var(--cherry-accent)', display:'inline-block', width: '100px', height: '160px'}}></div>
        <div style={{backgroundColor: 'var(--stone-12)', display: 'inline-block', width: '100px', height: '160px'}}></div>
      </div>
      <h3>Colores Principales</h3>
      <ul class="tag-list">
        <li class="background-primary">Primary Color</li>
        <li class="background-accent">Accent Color</li>
        <li class="background-danger">Danger Color</li>
      </ul>
      <h3>Text Colors</h3>
      <ul>
        <li>Text Color</li>
      </ul>

      <h4>Background Colors</h4>
      <ul class="tag-list">
        <li class="background-bglight">Background Light Color</li>
        <li class="background">Background Color</li>
      </ul>

      <h2>Callouts</h2>
      <Callout variant="info">Info Callout</Callout>
      <Callout variant="warning">Warning Callout</Callout>
      <Callout variant="danger">Danger Callout</Callout>

      <h2>Posts</h2>
      <p>Every post has a default image like this.</p>
      <PostImage/>
    </Layout>
  )
}

export const Head = () => <Seo title="Style Guide" />

export default StyleGuidePage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
