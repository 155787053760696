/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Como Google ha sacado su navegador ", React.createElement(_components.a, {
    href: "http://www.google.com/chrome"
  }, "Chrome"), " y me he quedado con las ganas de probarlo porque no está aun disponible para linux he pensado en instalarme Opera, para probar cosas nuevas. Lo malo es que no lo tenia en los repositorios. Así que me he ido a la ", React.createElement(_components.a, {
    href: "http://www.opera.com/download/"
  }, "pagina oficial de Opera"), " y me he bajado el paquete para mi distribución de Ubuntu. Lo siguiente ha sido instalarlo con el comando:"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "dpkg -i opera\\_<numerodeversion>.deb")), "\n", React.createElement(_components.p, null, "Ya tenemos instalado Opera el problema que tenemos ahora es el idioma que esta en inglés, para ponerlo en español nos bajamos el archivo correspondiente a nuestra version de opera de ", React.createElement(_components.a, {
    href: "http://www.opera.com/download/languagefiles/"
  }, "esta"), " página."), "\n", React.createElement(_components.p, null, "Movemos el archivo a la capeta /usr/share/opera/locale/ supongo que ya no hará falta que diga que para esto hay que usar el comando mv."), "\n", React.createElement(_components.p, null, "Por ultimo abrimos Opera y en Tools>Preferences pulsamos en Detais y en esa ventana clicamos en Choose y se abrirá un explorador de archivos, en este seleccionamos el archivo de idioma que pusimos en la carpeta locale. Y ya tendremos Opera en español."), "\n", React.createElement(_components.p, null, "Me gusta este navegador, tendre que probarlo durante mas tiempo pero la primera impresión ya ha sido buena."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
