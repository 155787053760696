/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    blockquote: "blockquote",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Busco tener un sistema con los minimos programas, a la ultima pero funcional, por eso decidí instalar kde4-minimal es un paquete con lo minimo para usar kde4. La version de Debian inestable en este momento es la kde4.2, otra cosa que hay que instalar es el kdm de los repositorios experimentales, si no es de estos nos dará un error de dependencias y nos intentará borrar el kde4-minimal."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "aptitude install kde4-minimal")), "\n"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "aptitude -t experimental install kdm")), "\n"), "\n", React.createElement(_components.p, null, "Y ya tenemos el escritorio instalado. El siguente paso será llenarlo de programas."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
