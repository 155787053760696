/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    blockquote: "blockquote",
    code: "code",
    span: "span",
    math: "math",
    semantics: "semantics",
    mrow: "mrow",
    mi: "mi",
    mn: "mn",
    mo: "mo",
    annotation: "annotation"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Cuando parecia que ya todo estaba medianamente aceptable abro Amarok y me sale un error de que no encuentra controlador, abro el YouTube y no se escucha la música. Miro en synaptic y no tenia instalado alsa. Pues a instalar el sonido se ha dicho."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "$aptitude install alsa-base alsa-utils alsa-tools")), "\n"), "\n", React.createElement(_components.p, null, "Una vez instalado esto deberia de ir pero cuando abro Amarok parece que si reproduce aunque yo no escucho nada. ¿Cual es el problema? ¡El volumen! Como tengo kde instalo kmix el mezclador de kde."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "$ aptitude install kmix")), "\n"), "\n", React.createElement(_components.p, null, "Abrimos el programa y ponemos todos los volumenes al máximo. Pero y si queremos tener el programa en un Tray(en la bandeja) cada vez que abramos nuestro sistema. Este truco ya lo he utilizado otras veces. Consiste en crear un archico en ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "H"), React.createElement(_components.mi, null, "O"), React.createElement(_components.mi, null, "M"), React.createElement(_components.mi, null, "E"), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "/"), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "."), React.createElement(_components.mi, null, "k"), React.createElement(_components.mi, null, "d"), React.createElement(_components.mi, null, "e"), React.createElement(_components.mn, null, "4"), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "/"), React.createElement(_components.mi, null, "A"), React.createElement(_components.mi, null, "u"), React.createElement(_components.mi, null, "t"), React.createElement(_components.mi, null, "o"), React.createElement(_components.mi, null, "s"), React.createElement(_components.mi, null, "t"), React.createElement(_components.mi, null, "a"), React.createElement(_components.mi, null, "r"), React.createElement(_components.mi, null, "t"), React.createElement(_components.mo, {
    separator: "true"
  }, ","), React.createElement(_components.mi, null, "r"), React.createElement(_components.mi, null, "e"), React.createElement(_components.mi, null, "c"), React.createElement(_components.mi, null, "o"), React.createElement(_components.mi, null, "r"), React.createElement(_components.mi, null, "d"), React.createElement(_components.mi, null, "a"), React.createElement(_components.mi, null, "d"), React.createElement(_components.mi, null, "q"), React.createElement(_components.mi, null, "u"), React.createElement(_components.mi, null, "e")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "HOME/.kde4/Autostart, recordad que ")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1em",
      verticalAlign: "-0.25em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.08125em"
    }
  }, "H"), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.02778em"
    }
  }, "O"), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.10903em"
    }
  }, "M"), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.05764em"
    }
  }, "E"), React.createElement(_components.span, {
    className: "mord"
  }, "/"), React.createElement(_components.span, {
    className: "mord"
  }, "."), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.03148em"
    }
  }, "k"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "d"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "e"), React.createElement(_components.span, {
    className: "mord"
  }, "4"), React.createElement(_components.span, {
    className: "mord"
  }, "/"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "A"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "u"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "o"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "s"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "a"), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.02778em"
    }
  }, "r"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "mpunct"
  }, ","), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.16666666666666666em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.02778em"
    }
  }, "r"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "e"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "c"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "o"), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.02778em"
    }
  }, "r"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "d"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "a"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "d"), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.03588em"
    }
  }, "q"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "u"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "e"))))), "HOME es vuestro directorio personal. En este caso crearemos un script de shell que inicie los programas que queramos. Como root ejecutamos este comando."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "$ nano $HOME/.kde4/Autostart/inicio.sh")), "\n"), "\n", React.createElement(_components.p, null, "Y copiamos esto dentro:"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "#!/bin/bash kmix"), "\n"), "\n", React.createElement(_components.p, null, "Guardamos con ctrl-o y salimos con ctrl-x. Luego le damos permisos de ejecución, puede ser graficamente en las propiedades del archivo. Y cuando iniciemos el sistema se nos abrira automaticamente."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
