/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h1: "h1",
    em: "em",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "He seguido ", React.createElement(_components.a, {
    href: "http://electrolito.entramado.net/2012/11/18/instalar-xbmc-en-raspberry-pi/",
    title: "Instalar XBMC en Raspberry Pi con Raspbian"
  }, "este tutorial de mi amigo Alex para instalar XBMC en la Raspberry Pi"), ", pero con esto no es suficiente, lo quiero tunear para que tenga todo lo que yo gasto y de esto va esta entrada, todo lo que he hecho para poner a mi gusto XBMC."), "\n", React.createElement(_components.h1, null, "Inicio automático"), "\n", React.createElement(_components.p, null, "En primer lugar quiero que XBMC se inicie automáticamente nada más arrancar la RPi, para esto editamos el archivo ", React.createElement(_components.em, null, "/etc/default/xbmc"), " para que quede de la siguiente forma: ", React.createElement(_components.code, null, "# Set this to 1 to enable startup ENABLED=1 # The user to run XBMC as USER=pi # Adjust niceness of XBMC (decrease for higher priority) NICE=-10")), "\n", React.createElement(_components.p, null, "De esta forma le estamos indicando que el usuario que vamos a usar para XBMC es el usuario ", React.createElement(_components.em, null, "pi"), ", el que viene por defecto en Raspbian. Además estamos incrementando el nivel de prioridad de XBMC, par que vaya más fluido."), "\n", React.createElement(_components.h1, null, "Activar apagado"), "\n", React.createElement(_components.p, null, "Para permitir apagar la Raspberry directamente, modificamos el archivo ", React.createElement(_components.em, null, "/var/lib/polkit-1/localauthority/50-local.d/xbmc.pkla"), " añadiendo lo siguiente: ", React.createElement(_components.code, null, "[Actions for xbmc user] Identity=unix-user:pi Action=org.freedesktop.upower.*;org.freedesktop.consolekit.system.* ResultAny=yes ResultInactive=yes ResultActive=yes")), "\n", React.createElement(_components.h1, null, "Manejo"), "\n", React.createElement(_components.p, null, "Una vez hecho esto ya podemos conectar la Raspberry Pi a nuestro televisor y manejarla con un teclado USB, el mando a distancia del televisor si tenemos ", React.createElement(_components.a, {
    href: "http://es.wikipedia.org/wiki/High-Definition_Multimedia_Interface#Canal_CEC_.28Consumer_Electronics_Control.29_.28opcional.29"
  }, "HDMI CEC"), " o como en mi caso utilizando el móvil, con la aplicación XBMC Remote, sólo tenemos que acordarnos de permitir la conexión mediante ", React.createElement(_components.em, null, "http"), ", yo estoy utilizando el puerto 1025 porque ya tengo en uso el puerto 8080."), "\n", React.createElement(_components.h1, null, "Servidor multimedia"), "\n", React.createElement(_components.p, null, "Otra funcionalidad de XBMC que me está encantando es poder utilizarla como servidor UPnP (algo parecido y en cierto modo compatible con el famoso DLNA). Mediante UPnP puedo compartir toda mi colección de música, vídeos e imágenes con cualquier dispositivo de mi red local que lo soporte, es decir que puedo ver películas en mi PC aún que estén a varios metros de distancia en un disco duro conectado a la RPi o tener toda mi música (muchos GB) a mano en el móvil mientras voy de un sitio a otro por la casa. Para Android me ha venido muy bien la versión gratuita de Mediahouse. Para GNU/Linux podéis buscar información sobre Grilo."), "\n", React.createElement(_components.h1, null, "Plugins"), "\n", React.createElement(_components.p, null, "También podemos instalar plugins como el que os voy a mostrar a continuación. Pelisalacarta nos permite conectarnos a un montón de servicios de streaming, yo en concreto uso Series.ly y este se encuentra entre ellos, os voy a explicar como instalarlo desde SSH de una forma rápida."), "\n", React.createElement(_components.p, null, "Primero buscamos en ", React.createElement(_components.a, {
    href: "http://blog.tvalacarta.info/plugin-xbmc/pelisalacarta/descargar/"
  }, "la web del plugin"), " su enlace, asegurándonos de que sea la última versión y que sea efectivamente para XBMC, lo copiamos y nos vamos a una terminal SSH: ", React.createElement(_components.code, null, "wget http://blog.tvalacarta.info/descargas/pelisalacarta-xbmc-addon-frodo-3.2.63.zip")), "\n", React.createElement(_components.p, null, "Y con esto se descarga el plugin en la versión actual. Despues desde XBMC, vamos a la sección de instalar plugins desde zip, lo buscamos en nuestra carpeta home (/home/pi) o donde lo hayamos descargado y lo instalamos sin más problemas (tarda un par de minutos)."), "\n", React.createElement(_components.p, null, "Y con esto lo tengo todo, lo que aún tengo pendiente es encontrar un ", React.createElement(_components.em, null, "Skin"), " bonito y ligero, muy ligero, que la RPi tampoco puede con mucho."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
